<template>
  <gl-icon
    v-popover:tooltip.top="`${tooltip}`"
    :height="15"
    name="actions-info"
    :width="15"
  />
</template>
<script>
import GlIcon from "@/components/gl-icon.vue";
export default {
  components: {
    GlIcon
  },
  props: {
    tooltip: {
      type: String,
      default: "Info",
      require: true
    }
  }
}
</script>
