<template>
  <gl-page-wrap title="Alerting">
    <template #actions>
      <GlButton
        dark
        large
        title="Create Alert"
        @click="handleOpenCreateModal"
      />
    </template>
    <div class="mb-4">
      <div class="flex fs-14 align-end mr-3">
        All Addresses
        <div class="px-1 switch__wrap">
          <input
            id="switch"
            v-model="isMonitoring"
            type="checkbox"
            @click="trackBy()"
          >
          <label for="switch">Toggle</label>
        </div>
        Monitoring
      </div>
    </div>
    <div class="fullwidth">
      <div class="table-wrap table-wrap__unoverflow gl-table-unshadow">
        <o-table
          ref="alertTable"
          class="alerting-table main-table-wrap"
          :class="{ 'o-table-has-pagination': list.length }"
          :data="list"
          hoverable
          :loading="loading"
          striped
        >
          <o-table-column
            v-slot="props"
            field="priority"
            label="Priority"
            sortable
          >
            <div v-if="props.row.priority === undefined">
              --
            </div>
            <gl-priority-chip
              v-else
              class="capitalize"
              :label="priorityMapLabels[props.row.priority] || '--'"
              :priority-level="props.row.priority"
              rounded="s"
              uppercase-mode
            />
            <div
              v-if="props.row.priority !== undefined"
              class="priority-border"
              :style="{'background-color': getPriorityColor(props.row.priority) || 'transparent'}"
            />
          </o-table-column>
          <o-table-column
            v-slot="props"
            field="title"
            label="Event type"
            sortable
            width="250px"
          >
            {{ props.row.title || '--' }}
          </o-table-column>
          <o-table-column
            v-slot="props"
            field="addresses"
            label="Wallet"
            sortable
          >
            <div
              v-if="props.row.monitoring"
            >
              Monitoring
            </div>
            <div v-else-if="!props.row.addresses || !props.row.addresses.length">
              --
            </div>
            <div
              v-else-if="props.row.addresses.length > 1"
              class="pointer link"
              @click="setAddresses(props.row.addresses)"
            >
              {{ props.row.addresses.length }} wallets
            </div>
            <div v-else>
              <div
                v-for="(wallet, index) in props.row.addresses"
                :key="index"
                class="link"
                @click="openInNewTabAddress(wallet, props.row)"
              >
                {{ trancateString(wallet, 7) }}
              </div>
            </div>
          </o-table-column>
          <o-table-column
            v-slot="props"
            field="updatedAt"
            label="Date Updated"
            sortable
          >
            {{ props.row.updatedAt ? formatDate(Date.parse(props.row.updatedAt), 'dd.MM.yyyy HH:mm') : '--' }}
          </o-table-column>
          <o-table-column
            v-slot="props"
            field="lastTriggered"
            label="Last Triggered"
            sortable
          >
            {{ props.row.lastTriggered ? formatDate(props.row.lastTriggered, 'dd.MM.yyyy HH:mm') : '--' }}
          </o-table-column>
          <o-table-column
            v-slot="props"
            field="triggeredCount"
            label="Times Triggered"
            sortable
          >
            {{ props.row.triggeredCount || 0 }}
          </o-table-column>
          <o-table-column
            v-slot="props"
            field="disabled"
            label="State"
            sortable
          >
            <gl-active
              v-model="props.row.disabled"
              :label="!props.row.disabled ? 'Active' : 'Inactive'"
              @input="updateActiveState(props.row)"
            />
          </o-table-column>
          <o-table-column
            v-slot="props"
            label="Actions"
            width="180px"
          >
            <div class="flex">
              <gl-icon
                v-popover:tooltip.top="'Edit'"
                class="mr-3 pointer"
                :height="24"
                name="edit-block-active"
                :width="24"
                @click="handleOpenEditModal(props.row)"
              />
              <gl-menu-item
                v-popover:tooltip.top="'Delete'"
                class="ml-2"
                fullwidth
                icon="delete"
                :icon-height="24"
                :icon-width="24"
                label=""
                warn
                @click="handleOpenDeleteModal(props.row)"
              />
            </div>
          </o-table-column>
          <template #empty>
            <div
              v-if="loading"
              class="flex align-center justify-center"
            >
              <gl-loader />
            </div>
            <div
              v-else
              class="empty-users-data flex column align-center"
            >
              <gl-icon
                :height="24"
                name="statistic"
                :width="24"
              />
              No data here yet
            </div>
          </template>
        </o-table>
        <div class="flex space-between pa-1">
          <div class="flex align-center pa-3">
            <div class="mr-2 fs-14 bold">
              Rows per page:
            </div>
            <vSelect
              v-model="perPage"
              class="stat-select__pagination mr-1"
              :clearable="false"
              :options="pagesOptions"
              :searchable="false"
              @input="countChange"
            />
          </div>
          <o-pagination
            v-if="list.length"
            class="stat-pagination"
            :current.sync="currentPage"
            order="right"
            :per-page="perPage"
            simple
            :total="total"
            @change="pageChange"
          >
            <o-pagination-button
              slot="previous"
              slot-scope="props"
              :page="props.page"
            >
              <gl-menu-item
                class="change-page mr-2"
                :disabled="props.page.disabled"
                icon="left"
                :icon-height="24"
                :icon-width="24"
                label=""
                not-outline
              />
            </o-pagination-button>

            <o-pagination-button
              slot="next"
              slot-scope="props"
              :page="props.page"
            >
              <gl-menu-item
                class="change-page"
                :disabled="props.page.disabled"
                icon="right"
                :icon-height="24"
                :icon-width="24"
                label=""
                not-outline
              />
            </o-pagination-button>
          </o-pagination>
        </div>
      </div>
      <AddressesListModal
        v-model="openAddressesListModal"
        :addresses="selectedAddress"
        @close="openAddressesListModal = false"
      />
      <create-alerts-modal
        v-if="openCreateModal"
        v-model="openCreateModal"
        :is-create="isCreate"
        @close="openCreateModal = false"
        @success="loadData(true)"
      />
      <editAlertModal
        v-if="openEditModal"
        v-model="openEditModal"
        :alert="selectedAlertData"
        :alert-id="selectedAlert"
        :is-create="isCreate"
        @close="openEditModal = false"
        @success="loadData"
      />
      <deleteAlertModal
        v-if="openDeleteModal"
        v-model="openDeleteModal"
        :alert="selectedAlert"
        @close="openDeleteModal = false"
        @remove="removeAlert"
      />
    </div>
  </gl-page-wrap>
</template>
<script>
// Components
import vSelect from "vue-select";
import GlIcon from "@/components/gl-icon.vue";
import GlButton from "@/components/gl-button.vue";
import GlLoader from "@/components/gl-loader.vue";
import GlActive from "@/components/gl-active.vue";
import GlMenuItem from "@/components/gl-menu-item.vue";
import GlPageWrap from "@/components/layout/gl-page-wrap.vue";
import GlPriorityChip from "@/components/gl-priority-chip.vue";
import AddressesListModal from "@/pages/alerts/modals/AddressesListModal.vue";
// Utils
import { formatDate } from "@/utils/format-date";
// Vuex
import { mapActions, mapState, mapMutations} from "vuex";
import DeleteAlertModal from "@/pages/alerting/deleteAlertModal.vue";
import {getPriorityColor, priorityMapLabels} from "@/utils/priority";
import CreateAlertsModal from "@/pages/alerting/createAlertsModal.vue";
import editAlertModal from "@/pages/alerting/editAlertModal.vue";
import GlRadio from "@/components/gl-radio-button.vue";
import {trancateString} from "../../utils/text-formatter";

export default {
  components: {
    GlRadio,
    vSelect,
    DeleteAlertModal,
    CreateAlertsModal,
    editAlertModal,
    GlButton,
    GlPageWrap,
    AddressesListModal,
    GlPriorityChip,
    GlIcon,
    GlLoader,
    GlMenuItem,
    GlActive
  },
  data() {
    return {
      isMonitoring: false,
      loading: false,
      list: [],
      selectedAddress: [],
      openAddressesListModal: false,
      selectedAlert: null,
      selectedAlertData: {},
      openCreateModal: null,
      openEditModal: null,
      openDeleteModal: null,
      isCreate: true,
      addressesCalculateHandler: null,
      pagesOptions: [5, 10, 20],
      currentPage: 1,
      perPage: 10,
      totalPages: 1,
      total: 1,
    }
  },
  computed: {
    priorityMapLabels() {
      return priorityMapLabels
    },
    ...mapState('analytics', ['coinData', 'currencyList'])
  },
  created() {
    this.monitoringGettingInterval(100)
  },
  beforeDestroy() {
    clearTimeout(this.addressesCalculateHandler);
  },
  mounted() {
    this.loadData()

    if (this.$route.params.alertId) {
      this.getAlertsById(this.$route.params.alertId).then((alertData) => {
        if (alertData.id && !alertData.deleted) {
          this.getAddressesForAlert(this.$route.params.alertId).then((data) => {
            this.handleOpenEditModal({ ...alertData, ...data , id: this.$route.params.alertId })
          })
        } else {
          this.$toasted.global.info({ message: `The alert ${alertData.title || ''} was deleted` })
        }
      })
    }
  },
  methods: {
    trancateString,
    getPriorityColor,
    ...mapMutations('analytics', ['SET_COIN_TYPE', 'SET_COIN_DATA']),
    ...mapActions('alerts', ['getAlertsList', 'deleteAlertByCoin', 'getAlertStat', 'deleteAlert', 'deleteAlertToAddressPair', 'getAlertDataByAddress', 'getAddressesForAlert', 'getAlertsById', 'updateAlert']),
    formatDate,
    updateActiveState({ id, disabled }) {
      this.updateAlert({ id, data: { disabled } }).then(() => {
        this.loadData()
      }).catch(() => {
        const indexAlert = this.list.findIndex(el => el.id === id)

        if (indexAlert !== -1) {
          this.list[indexAlert].disabled = !disabled
        }
      })
    },
    trackBy() {
      this.isMonitoring = !this.isMonitoring
      this.loadData()
    },
    countChange() {
      this.loadData()
    },
    pageChange(event) {
      this.currentPage = event
      this.loadData()
    },
    formattedSendData() {
      return {
        monitoring: this.isMonitoring || undefined,
        $limit: this.perPage,
        $skip: (this.currentPage - 1) * this.perPage,
      }
    },
    monitoringGettingInterval(interval = 12000) {
      this.addressesCalculateHandler = setTimeout(() => {
        this.loadData(false).finally(() => this.monitoringGettingInterval())
      }, interval);
    },
    handleOpenCreateModal() {
      this.isCreate = true
      this.openCreateModal = true
    },
    handleOpenEditModal(alert) {
      if (alert.coin) {
        const coinData = this.currencyList.find(({ key }) => key === alert.coin) || null

        if (coinData) {
          this.SET_COIN_DATA(coinData)
          this.SET_COIN_TYPE(coinData.key)
        }
      }

      this.isCreate = false
      this.selectedAlert = alert.id
      this.selectedAlertData = alert
      this.openEditModal = true
    },
    handleOpenDeleteModal(alert) {
      this.selectedAlert = alert
      this.openDeleteModal = true
    },
    removeAlert(alert) {
      if (alert?.addresses?.length) {
        const url = this.currencyList.find(({ key }) => key === alert.coin)?.baseUrl || null

        if (url) {
          this.deleteAlertByCoin({ id: alert.id, url }).then(() => {
            this.deleteAlert(alert.id).then(() => {
              this.openDeleteModal = false
              this.selectedAlert = null
              this.loadData()
            }).catch(() => {
              this.$toasted.global.error({ message: 'Request error' })
            })
          })
        }
      } else {
        this.deleteAlert(alert.id).then(() => {
          this.openDeleteModal = false
          this.selectedAlert = null
          this.loadData()
        }).catch(() => {
          this.$toasted.global.error({ message: 'Request error' })
        })
      }
    },
    async loadData(withLoading = true) {
      if (withLoading) {
        this.loading = true;
      }

      try {
        const sendParams = this.formattedSendData();
        const alerts = await this.getAlertsList(sendParams);

        this.total = alerts.count || 0;
        this.totalPages = Math.ceil(this.total / this.perPage);

        this.list = await Promise.all(
          alerts.items.reverse().map(async (el) => {
            let shouldUpdate = false;

            const data = await this.getAddressesForAlert(el.id).catch((data) => {
              console.log(data)
            });

            if (data?.length) {
              el.addresses = data?.map(({ address }) => {
                return address
              })

              el.coin = data[0].coin || null
            }

            try {
              if (el?.addresses?.length > 1 || el?.monitoring) {
                const alertData = await this.getAlertStat({
                  alertId: el.id,
                });

                const { lastAt = null, count = 0 } = alertData.items[0]

                if (el.triggeredCount !== count) {
                  shouldUpdate = true;
                  this.$set(el, 'triggeredCount', count);
                  this.$set(el, 'lastTriggered', lastAt && Date.parse(lastAt));
                }
              } else if (el?.addresses && el?.addresses[0]) {
                const alertData = await this.getAlertStat({
                  alertId: el.id,
                });

                const { lastAt = null, count = 0 } = alertData.items[0]

                if (el?.triggeredCount !== count) {
                  shouldUpdate = true;
                  this.$set(el, 'triggeredCount', count);
                  this.$set(el, 'lastTriggered', lastAt && Date.parse(lastAt));
                }
              }
            } catch (e) {
              console.log(e)
            }

            return shouldUpdate ? {...el} : el;
          })
        );

      } finally {
        if (withLoading) {
          this.loading = false;
        }
      }
    },
    setAddresses(addresses) {
      this.selectedAddress = addresses || []
      if (this.selectedAddress.length) {
        this.openAddressesListModal = true
      }
    },
    openInNewTabAddress(address, alert) {
      const { href } = this.$router.resolve({ name: 'analytics', query: { address, type: alert.coin } })
      window.open(href, '_blank')
    },
  },
}
</script>

<style>
.alerting-table .o-table__td {
  position: relative;
}

.priority-border {
  width: 4px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.filters-wrap {
  background-color: #fff;
  padding: 16px;
  border-radius: 3px;
  box-shadow: 0 0 8px 0 rgba(211, 211, 211, 0.64);
}
</style>

