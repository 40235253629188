<template>
  <gl-modal
    v-bind="$attrs"
    capitalize-title
    info-only
    overflowing
    ticker="%"
    :title="isCreate ? 'Create Alert' : 'Edit Alert'"
    to-end-buttons
    width="70"
    v-on="$listeners"
    @close="$emit('close')"
  >
    <template #content>
      <div class="alert-wrap mb-3">
        <monitoringAddressesAlert
          v-if="alert.monitoring"
          :alert-id="alertId"
          :is-create="isCreate"
          @close="$emit('close')"
          @success="$emit('success')"
        />
        <bulkAddressesAlert
          v-else-if="alert.addresses && alert.addresses.length > 1"
          :alert-id="alertId"
          :is-create="isCreate"
          @close="$emit('close')"
          @success="$emit('success')"
        />
        <SingleAddressAlert
          v-else
          :alert-id="alertId"
          :is-create="isCreate"
          @close="$emit('close')"
          @success="$emit('success')"
        />
      </div>
    </template>
  </gl-modal>
</template>

<script>
// Components
import GlModal from '@/components/gl-modal';
import SingleAddressAlert from "@/pages/alerting/components/singleAddressAlert.vue";
import BulkAddressesAlert from "@/pages/alerting/components/bulkAddressesAlert.vue";
import MonitoringAddressesAlert from "@/pages/alerting/components/monitoringAddressesAlert.vue";

export default {
  components: {
    MonitoringAddressesAlert,
    BulkAddressesAlert,
    GlModal,
    SingleAddressAlert,
  },
  inheritAttrs: false,
  props: {
    isCreate: {
      type: Boolean,
      default: false,
    },
    alertId: {
      type: [String, Number],
      default: 0,
      require: true
    },
    alert: {
      type: Object,
      default: () => ({}),
      require: true
    },
  },
}
</script>
