<template>
  <gl-modal
    v-bind="$attrs"
    submit-title="Delete"
    title="Delete Alert"
    v-on="$listeners"
    full-buttons
    @close="$emit('close')"
    @submit="onSubmit"
  >
    <div class="mb-4">
      Are you sure you want to delete alert <b>{{ alert.title || '' }}</b>?
    </div>
  </gl-modal>
</template>

<script>
import GlModal from '@/components/gl-modal'

export default {
  components: {
    GlModal,
  },
  inheritAttrs: false,
  props: {
    alert: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    onSubmit() {
      this.$emit('remove', this.alert)
      this.$emit('input', false)
    },
  },
}
</script>
