<template>
  <GlModal
    v-bind="$attrs"
    capitalize-title
    closable
    full-submit
    ok-only
    overflowing
    submit-title="Ok"
    title="Alert Created"
    width="400"
    v-on="$listeners"
    @close="$emit('close')"
    @submit="$emit('close')"
  >
    <div class="flex align-center mb-3 fs-14">
      <div>
        {{ data.count }} addresses uploaded and created alerts.
      </div>
    </div>
    <div class="flex align-center mb-3 fs-14">
      <div class="warning-text">
        {{ data.invalid }} addresses invalid.
      </div>
    </div>
  </GlModal>
</template>

<script>
// Components
import GlModal from '@/components/gl-modal'

export default {
  components: {
    GlModal,
  },
  inheritAttrs: false,
  props: {
    data :{
      type: Object,
      default: () => ({})
    },
  }
}
</script>
